@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-Regular.otf");
    font-weight: 400;
}

@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-SemiBold.otf");
    font-weight: 600;
}

body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --v2-ui-2-strong: "Pretendard JP";

    /* font sizes */
    --v2-ui-3-strong-size: 24px;
    --v2-ui-2-strong-size: 15px;
    --v2-ui-1-strong-size: 17px;
    --v2-headline-6-size: 19px;

    /* Colors */
    --color-surface-default: #fff;
    --color-text-subtle: #3e414a;
    --text-primary: #32373e;
    --color-text-default: #2a2c32;
    --color-background-disabled: rgba(30, 43, 68, 0.08);
    --color-gray-100: #7c7f85;
    --color-text-disabled: rgba(10, 20, 55, 0.2);
    --color-text-subtlest: #707684;
    --color-background-accent-pink-bolder: #fa5b8c;

    /* Spacing */
    --corner-radius-cornerradius016: 16px;
    --size-size016: 16px;
    --size-size008: 8px;
    --size-size020: 20px;
    --size-size056: 56px;
    --size-size002: 2px;

    /* Gaps */
    --gap-base: 16px;
    --gap-9xs: 4px;

    /* Paddings */
    --padding-5xl: 24px;
    --padding-xl: 20px;

    /* Border radiuses */
    --br-base: 16px;
    --br-980xl: 999px;
}

.pLoginMain{
    margin-bottom: 10px;
}
.p1LoginMain {
    margin-top: 10px;
}

.p2LoginMain {
    margin: 0;
    display: flex;
    font-size: var(--v2-ui-3-strong-size);
    color: var(--color-text-subtlest);
    align-items: flex-start;
    margin-right: 12px;
}


.div1LoginMain {
    align-self: stretch;
    position: relative;
    line-height: auto;
    font-weight: 600;
    font-size: var(--v2-headline-6-size);
    float: left;
}

.button1LoginMain {
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: var(--br-980xl);
    background-color: var(--color-gray-100);
    width: 6px;
    height: 6px;
    overflow: hidden;
}

.div2LoginMain {
    display: flex;
    position: relative;
}

.buttonLoginMain {
    height: 20px;
    width: 20px;
}

.div2LoginMain {
    flex: 1;
    line-height: 20px;
    margin-bottom: 24px;
}

.button-parentLoginMain {
    height: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    font-size: var(--v2-ui-2-strong-size);
    color: var(--color-text-subtlest);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.frame-childLoginMain {
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: var(--br-980xl);
    background-color: var(--color-gray-100);
    width: 6px;
    height: 6px;
    overflow: hidden;
}

.frame-wrapperLoginMain {
    height: 20px;
    width: 20px;
    position: relative;
}

.div3LoginMain {
    display: flex;
    position: relative;
}

.div3LoginMain {
    flex: 1;
    position: relative;
    line-height: 20px;
    margin-bottom: 36px;
}

.frame-parentLoginMain {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.frame-itemLoginMain {
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: var(--br-980xl);
    background-color: var(--color-gray-100);
    width: 6px;
    height: 6px;
    overflow: hidden;
}

.frame-containerLoginMain {
    height: 20px;
    width: 20px;
    position: relative;
}

.div4LoginMain {
    flex: 1;
    position: relative;
    line-height: 20px;
}

.frame-groupLoginMain {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.frame-innerLoginMain {
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: var(--br-980xl);
    background-color: var(--color-gray-100);
    width: 6px;
    height: 6px;
    overflow: hidden;
}

.frame-wrapper1LoginMain {
    height: 20px;
    width: 20px;
    position: relative;
}

.div5LoginMain {
    flex: 1;
    position: relative;
    line-height: 20px;
}

.parentLoginMain {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.frame-divLoginMain {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-9xs);
}

.parentLoginMain {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    font-size: var(--v2-ui-2-strong-size);
    color: var(--color-text-subtlest);
}

.parentLoginMain {
    padding: 0 var(--padding-5xl);
    text-align: left;
    font-size: var(--v2-headline-6-size);
    color: var(--color-text-default);
    font-family: var(--v2-ui-2-strong);
}

.container-help-textLoginMain,
.textLoginMain {
    overflow: hidden;
    max-width: 100%;
}

.textLoginMain {
    height: 20px;
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-help-textLoginMain {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--size-size002) 0;
    box-sizing: border-box;
}

.text-labelLoginMain {
    height: 20px;
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--v2-ui-2-strong);
    color: var(--color-text-subtle);
    text-align: center;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerLoginMain {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.containerLoginMain {
    height: var(--size-size056);
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-disabled);
    display: flex;
    padding: 0 var(--size-size020);
    box-sizing: border-box;
}

.button-button-4LoginMain {
    align-self: stretch;
    overflow: hidden;
    display: none;
}

.text-label1LoginMain {
    height: 20px;
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--v2-ui-2-strong);
    color: var(--color-text-subtle);
    text-align: center;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container1LoginMain {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.container1LoginMain {
    height: var(--size-size056);
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-disabled);
    display: flex;
    padding: 0 var(--size-size020);
    box-sizing: border-box;
}

.button-button-3LoginMain {
    align-self: stretch;
    overflow: hidden;
    display: none;
}

.text-label2LoginMain {
    height: 20px;
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--v2-ui-2-strong);
    color: var(--color-text-subtle);
    text-align: center;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container2LoginMain {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.container2LoginMain {
    height: var(--size-size056);
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-disabled);
    display: flex;
    padding: 0 var(--size-size020);
    box-sizing: border-box;
}

.button-button-2LoginMain {
    align-self: stretch;
    overflow: hidden;
    display: none;
}

.text-label3OnLoginMain {
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--v2-ui-2-strong);
    color: var(--color-surface-default);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container3OnLoginMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.container3OnLoginMain {
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-accent-pink-bolder);
    padding: var(--padding-lg) var(--size-size020);
    box-sizing: border-box;
    white-space: nowrap;
}

.text-label3LoginMain {
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--v2-ui-2-strong);
    color: var(--color-text-disabled);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container3LoginMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: var(--size-size056);
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-disabled);
    padding: 0 var(--size-size020);
    box-sizing: border-box;
}

.button-button-1LoginMain {
    align-self: stretch;
    overflow: hidden;
}

.container-button-groupLoginMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    align-items: flex-start;
    gap: var(--size-size008);
}

.button-dockLoginMain {
    width: 100%;
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    left: calc(50% - 180px);
    overflow: hidden;
    align-items: center;
    padding: 0px 18px 32px;
    box-sizing: border-box;
    gap: var(--size-size016);
    text-align: center;
    font-size: var(--v2-ui-1-strong-size);
    color: var(--color-text-subtlest);
    font-family: var(--v2-ui-2-strong);
}

.ic-radio-checkbox-off-iconLoginMain {
    height: 24px;
    width: 24px;
    position: relative;
    margin-right: 17.5px;
}

.text-label4LoginMain {
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    font-size: var(--v2-ui-2-strong-size);
}

.chevron-right-iconLoginMain {
    height: 24px;
    width: 24px;
    position: relative;
    display: none;
}

.container4LoginMain {
    align-self: stretch;
    border-radius: var(--corner-radius-cornerradius016);
    flex-direction: row;
    align-items: flex-start;
    padding: 0 var(--padding-5xl);
    gap: var(--gap-base);
}

.divLoginMain {
    width: auto;
    height: 100dvh;
    background-color: var(--color-surface-default);
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    padding: 128px 0 0;
    box-sizing: border-box;
    position: relative;
    gap: 72px;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--v2-ui-2-strong-size);
    color: var(--text-primary);
    font-family: var(--v2-ui-2-strong);
}

.divButtonLogin {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0px 18px 32px;
}

.divBoxButtonLogin {
    width: 100%;
    position: relative;
    background-color: var(--color-surface-default);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding: 0px 18px 32px;
}