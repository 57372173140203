@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-Regular.otf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-SemiBold.otf");
    font-weight: 600;
  }
  
  body {
    margin: 0;
    line-height: normal;
  }
  :root {
    /* fonts */
    --v2-ui-1-strong: "Pretendard JP";
  
    /* font sizes */
    --v2-ui-1-strong-size: 17px;
    --v2-ui-2-size: 15px;
    --v2-headline-6-size: 19px;
  
    /* Colors */
    --color-surface-default: #fff;
    --color-background-accent-pink-bolder: #fa5b8c;
    --color-text-subtle: #3e414a;
    --color-text-default: #2a2c32;
    --color-background-neutral-default: rgba(30, 43, 68, 0.08);
    --color-text-subtlest: #707684;
  
    /* Spacing */
    --size-size016: 16px;
    --size-size008: 8px;
    --size-size020: 20px;
    --size-size056: 56px;
    --corner-radius-cornerradius016: 16px;
    --size-size002: 2px;
  
    /* Gaps */
    --gap-base: 16px;
    --gap-5xs: 8px;
  
    /* Paddings */
    --padding-base: 16px;
    --padding-15xl: 34px;
    --padding-lg: 18px;
    --padding-xl: 20px;
    --padding-11xs: 2px;
    --padding-5xl: 24px;
  
    /* Border radiuses */
    --br-base: 16px;
  }

.caution-icon {
    width: 80px;
    height: 80px;
  }
  .caution-icon,
  .div1,
  .div2 {
    position: relative;
  }
  .div1 {
    align-self: stretch;
    line-height: 28px;
    font-weight: 600;
  }
  .div2 {
    flex: 1;
    line-height: 20px;
  }

  .recheck-account-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }


  .recheck-account-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .recheck-account-wrapper {
    font-size: var(--v2-ui-2-size);
    color: var(--color-text-subtlest);
  }

  .caution-icon1 {
    gap: var(--gap-5xs);
    margin-top: 16px;
  }

  .caution-icon2 {
    gap: var(--gap-5xs);
    margin-top: 16px;
  }

  .button-dock {
    height: 152px;
    padding: 0 var(--padding-5xl);
    box-sizing: border-box;
    gap: var(--gap-base);
    text-align: center;
    font-size: var(--v2-headline-6-size);
    color: var(--color-text-default);
    font-family: var(--v2-ui-1-strong);
    justify-content: center;
    align-items: center;
    transform: translate(0, -70%);
  }