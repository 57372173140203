@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-Regular.otf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-SemiBold.otf");
    font-weight: 600;
  }
  
  body {
    margin: 0;
    line-height: normal;
  }
  :root {
    /* fonts */
    --v2-ui-1-strong: "Pretendard JP";
  
    /* font sizes */
    --v2-ui-1-strong-size: 17px;
    --v2-ui-2-size: 15px;
    --v2-headline-6-size: 19px;
  
    /* Colors */
    --color-surface-default: #fff;
    --color-background-accent-pink-bolder: #fa5b8c;
    --color-text-subtle: #3e414a;
    --color-text-default: #2a2c32;
    --color-background-neutral-default: rgba(30, 43, 68, 0.08);
    --color-text-subtlest: #707684;
  
    /* Spacing */
    --size-size016: 16px;
    --size-size008: 8px;
    --size-size020: 20px;
    --size-size056: 56px;
    --corner-radius-cornerradius016: 16px;
    --size-size002: 2px;
  
    /* Gaps */
    --gap-base: 16px;
    --gap-5xs: 8px;
  
    /* Paddings */
    --padding-base: 16px;
    --padding-15xl: 34px;
    --padding-lg: 18px;
    --padding-xl: 20px;
    --padding-11xs: 2px;
    --padding-5xl: 24px;
  
    /* Border radiuses */
    --br-base: 16px;
  }

.container-help-text,
.text {
  overflow: hidden;
  max-width: 100%;
}
.text {
  height: 20px;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container-help-text {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--size-size002) 0;
  box-sizing: border-box;
}
.text-label {
  height: 20px;
  flex: 1;
  position: relative;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 20px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-text-subtle);
  text-align: center;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-button-4,
.container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.container {
  height: var(--size-size056);
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  background-color: var(--color-background-neutral-default);
  display: flex;
  padding: 0 var(--size-size020);
  box-sizing: border-box;
}
.button-button-4 {
  align-self: stretch;
  overflow: hidden;
  display: none;
}
.text-label1 {
  height: 20px;
  flex: 1;
  position: relative;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 20px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-text-subtle);
  text-align: center;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-button-3,
.container1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.container1 {
  height: var(--size-size056);
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  background-color: var(--color-background-neutral-default);
  display: flex;
  padding: 0 var(--size-size020);
  box-sizing: border-box;
}
.button-button-3 {
  align-self: stretch;
  overflow: hidden;
  display: none;
}
.text-label2 {
  height: 20px;
  flex: 1;
  position: relative;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 20px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-text-subtle);
  text-align: center;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-button-2,
.container2 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.container2 {
  height: var(--size-size056);
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  background-color: var(--color-background-neutral-default);
  display: flex;
  padding: 0 var(--size-size020);
  box-sizing: border-box;
}
.button-button-2 {
  align-self: stretch;
  overflow: hidden;
  display: none;
}
.text-label3 {
  flex: 1;
  position: relative;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 20px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-surface-default);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button-button-1,
.container3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.container3 {
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  background-color: var(--color-background-accent-pink-bolder);
  padding: var(--padding-lg) var(--size-size020);
  box-sizing: border-box;
  white-space: nowrap;
}
.button-button-1 {
  align-self: stretch;
  overflow: hidden;
}
.button-dock1,
.container-button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--size-size008);
}

.button-dock1 {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  align-items: center;
  padding: 0 var(--size-size016) var(--padding-15xl);
  box-sizing: border-box;
  gap: var(--size-size016);
  text-align: center;
  font-size: var(--v2-ui-1-strong-size);
  color: var(--color-text-subtlest);
  font-family: var(--v2-ui-1-strong);
}