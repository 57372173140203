@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-SemiBold.otf");
    font-weight: 600;
  }
  
  body {
    margin: 0;
    line-height: normal;
  }
  :root {
    /* fonts */
    --v2-ui-1-strong: "Pretendard JP";
  
    /* font sizes */
    --v2-ui-1-strong-size: 17px;
    --v2-small-text-1-strong-size: 13px;
    --v2-headline-3-size: 27px;
  
    /* Colors */
    --color-surface-default: #fff;
    --color-background-accent-gray-subtlest: #edeef0;
    --color-text-subtle: #3e414a;
    --color-text-default: #2a2c32;
    --color-text-subtlest: #707684;
  
    /* Spacing */
    --size-size016: 16px;
    --corner-radius-cornerradius012: 12px;
    --size-size008: 8px;
    --size-size020: 20px;
    --corner-radius-cornerradius016: 16px;
    --size-size024: 24px;
  
    /* Gaps */
    --gap-5xs: 8px;
  
    /* Paddings */
    --padding-base: 16px;
    --padding-xl: 20px;
    --padding-19xl: 38px;
  }




.h3Login,
.pLogin,
.p1Login {
  margin: 0;
}
.h3Login {
  align-self: stretch;
  position: relative;
  font-size: 27px;
  line-height: 36px;
  font-weight: 600;
  font-family: inherit;
}
.img-main-logo-valentine-iconLogin {
  width: 140px;
  height: 17px;
  position: relative;
  object-fit: cover;
}
.parentLogin {
  display: flex;
  align-items: center;
}
.parentLogin {
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
  font-size: var(--v2-headline-3-size);
  color: var(--color-text-default);
  font-family: var(--v2-ui-1-strong);
  margin-bottom: 120px;
}
.div1Login {
  height: 35px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  justify-content: center;
  flex-shrink: 0;
}
.icon-googleLogin {
  height: 18px;
  width: 18px;
}
.labelLogin {
  position: relative;
}
.labelLogin {
  display: inline-flex;
  flex: 1;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-text-subtle);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.iconLogin {
  height: var(--size-size024);
  width: var(--size-size024);
  display: none;
  min-height: 24px;
}
.buttonLogin,
.contentLogin {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentLogin {
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  flex-direction: row;
  padding: 0 var(--size-size020);
  gap: var(--size-size008);
}
.buttonLogin {
  width: 311px;
  cursor: pointer;
  border: 1px solid var(--color-background-accent-gray-subtlest);
  padding: var(--size-size016) var(--padding-19xl);
  background-color: var(--color-surface-default);
  height: 56px;
  border-radius: var(--corner-radius-cornerradius012);
  box-sizing: border-box;
  flex-direction: column;
}
.icon-appleLogin {
  height: 18px;
  width: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.label1Login {
  position: relative;
}
.label1Login {
  display: inline-flex;
  flex: 1;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-surface-default);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon1Login {
  height: var(--size-size024);
  width: var(--size-size024);
  display: none;
  min-height: 24px;
}
.content1Login {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1Login {
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  flex-direction: row;
  padding: 0 var(--size-size020);
  gap: var(--size-size008);
}
.button1Login {
  width: 311px;
  cursor: pointer;
  border: 0;
  padding: var(--size-size016) 44.5px;
  background-color: #000;
  height: 56px;
  border-radius: var(--corner-radius-cornerradius012);
  flex-direction: column;
  box-sizing: border-box;
}
.icon-kakaoLogin {
  height: 18px;
  width: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.label2Login {
  position: relative;
}
.label2Login {
  display: inline-flex;
  flex: 1;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon2Login {
  height: var(--size-size024);
  width: var(--size-size024);
  display: none;
  min-height: 24px;
}
.button2Login,
.content2Login {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2Login {
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  flex-direction: row;
  padding: 0 var(--size-size020);
  gap: var(--size-size008);
}
.button2Login {
  width: 311px;
  cursor: pointer;
  border: 0;
  padding: var(--size-size016) 45.5px;
  background-color: #fee500;
  height: 56px;
  border-radius: var(--corner-radius-cornerradius012);
  flex-direction: column;
  box-sizing: border-box;
}
.icon-facebookLogin {
  height: 18px;
  width: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.label3Login {
  position: relative;
}
.label3Login {
  display: inline-flex;
  flex: 1;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-surface-default);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon3Login {
  height: var(--size-size024);
  width: var(--size-size024);
  display: none;
  min-height: 24px;
}
.button3Login,
.content3Login {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content3Login {
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  flex-direction: row;
  padding: 0 var(--size-size020);
  gap: var(--size-size008);
}
.button3Login {
  width: 311px;
  cursor: pointer;
  border: 0;
  padding: var(--size-size016) var(--padding-19xl);
  background-color: #3875ea;
  height: 56px;
  border-radius: var(--corner-radius-cornerradius012);
  flex-direction: column;
  box-sizing: border-box;
}
.icon-lineLogin {
  height: 18px;
  width: 18px;
}
.label4Login {
  position: relative;
}
.label4Login {
  display: inline-flex;
  flex: 1;
  font-size: var(--v2-ui-1-strong-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--v2-ui-1-strong);
  color: var(--color-surface-default);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon4Login {
  height: var(--size-size024);
  width: var(--size-size024);
  display: none;
  min-height: 24px;
}
.button4Login,
.content4Login {
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.content4Login {
  flex: 1;
  border-radius: var(--corner-radius-cornerradius016);
  display: flex;
  flex-direction: row;
  padding: 0 var(--size-size020);
  gap: var(--size-size008);
}
.button4Login {
  width: 311px;
  cursor: pointer;
  border: 0;
  padding: var(--size-size016) 49.5px;
  background-color: #06c755;
  height: 56px;
  border-radius: var(--corner-radius-cornerradius012);
  box-sizing: border-box;
}
.button-parentLogin,
.button4Login,
.groupLogin {
  display: flex;
  flex-direction: column;
}
.button-parentLogin {
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
}
.groupLogin {
  align-items: center;
}
.groupLogin {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 6px;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--v2-small-text-1-strong-size);
  color: var(--color-text-subtlest);
  font-family: var(--v2-ui-1-strong);
}
.divLogin {
  position: absolute;
  bottom: 0;
  padding: 0px 18px 32px;
}
.divMainLogin{
  width: 100%;
  height: 100dvh;
  position: relative;
  background-color: var(--color-surface-default);
  overflow: hidden;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
}

.divComponentLogin {
  position: absolute;
  bottom: 0;
  padding: 0px 18px 32px;
}